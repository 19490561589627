import http from "../http-common";

class ChatService {
    getFcmUserDetail(data) {
        return http.get("secure-chat/user/detail", { params: data });
    }
    createPowerChatUser(data) {
        return http.post("secure-chat/user/create", data);
    }
    sendRequest(data) {
        return http.post("secure-chat/user/request/sent", data);
    }
    approveRequest(data) {
        return http.post("secure-chat/user/request/approve", data);
    }
}

export default new ChatService();