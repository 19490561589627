import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import CanvasJSChart from '@canvasjs/vue-charts';

const app =  createApp(App);
app.use(router);
app.use(ToastPlugin);
app.use(CanvasJSChart);
app.mount('#app');
