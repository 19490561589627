<template>
<div class="container">
    <div class="mb-3">
        <div style="float: left">
            <a href="javascript:void(0)" @click="redirectBack()"><i class="fa fa-fw fa-arrow-left"></i></a>
        </div>
        <div style="margin: 0 auto; width: 100px;"><h5><b>Settings</b></h5></div>
    </div><hr>
  <div class="row">
	<div class="col-md-12 col-xl-12"> 
        <div class="form-group">
            <input type="number" autofocus min="0" v-model="lockScreenTimeOut" class="form-control" aria-describedby="emailHelp" placeholder="Enter Screen Lock Timeout">
            <small id="emailHelp" class="form-text text-muted">In Seconds.</small>
        </div>
        <div class="form-group">
            <textarea v-model="parentMessage" class="form-control" placeholder="Enter Message For Kid"></textarea>
        </div>
	</div>
	<div class="col-md-12 text-center mb-3"> 
		<button type="button" class="btn btn-primary w-100" @click="updateProfile()" :disabled="isDisabled">Save</button>
	</div>
  </div>

</div>
</template>

<script>
import ProfileService from "../services/ProfileService";
export default {
  name: 'Settings',
    data() {
        return {
            isDisabled: false,
            profileId: this.$route.query.profileId,
            profileDetail: null,
            lockScreenTimeOut: null,
            parentMessage: null
        }
    },
    methods: {
        getDeviceProfileDetail() {
        ProfileService.getDeviceProfileDetail({profile_id: this.profileId})
            .then((response) => {
                this.profileDetail = response.data.data;
                this.lockScreenTimeOut = response.data.data.lockScreenTimeOut;
                this.parentMessage = response.data.data.parentMessage;
            }).catch((e) => {
                this.$toast.error(e);
            });
        },
        async updateProfile() {
            this.isDisabled = true;
            var data = {
                profile_id: this.profileId,
                profile_name: this.profileDetail.profile_name,
                outgoing_calls_disabled: this.profileDetail.outgoing_calls_disabled,
                sms_disabled: this.profileDetail.sms_disabled,
                bluetooth_disabled: this.profileDetail.bluetooth_disabled,
                outgoing_beam_disabled: this.profileDetail.outgoing_beam_disabled,
                add_user_disabled: this.profileDetail.add_user_disabled,
                remove_user_disabled: this.profileDetail.remove_user_disabled,
                modify_accounts_disabled: this.profileDetail.modify_accounts_disabled,
                mobile_networks_config_disabled: this.profileDetail.mobile_networks_config_disabled,
                tethering_config_disabled: this.profileDetail.tethering_config_disabled,
                wifi_config_disabled: this.profileDetail.wifi_config_disabled,
                screen_capture_disabled: this.profileDetail.screen_capture_disabled,
                camera_disabled: this.profileDetail.camera_disabled,
                keyguard_disabled: this.profileDetail.keyguard_disabled,
                status_bar_disabled: this.profileDetail.status_bar_disabled,
                adjust_volume_disabled: this.profileDetail.adjust_volume_disabled,
                install_type: "",
                developer_settings: this.profileDetail.developer_settings,
                install_unknown_sources_allowed: this.profileDetail.install_unknown_sources_allowed,
                safe_boot_disabled: this.profileDetail.safe_boot_disabled,

                // Password Policy
                password_type: this.profileDetail.passwordQuality,
                min_password_length: this.profileDetail.passwordMinimumLength,
                min_number_of_symbols: this.profileDetail.passwordMinimumSymbols,
                min_number_of_alphabets: this.profileDetail.passwordMinimumLetters,
                min_number_of_lower_case_characters: this.profileDetail.passwordMinimumLowerCase,
                min_number_of_upper_case_characters: this.profileDetail.passwordMinimumUpperCase,
                min_number_of_digits: this.profileDetail.passwordMinimumNumeric,
                max_password_history_list: this.profileDetail.passwordHistoryLength,
                max_failed_attempt_to_factory_reset: this.profileDetail.maximumFailedPasswordsForWipe,
                password_expiry_period: this.profileDetail.passwordExpirationTimeout,
                idle_time_for_auto_lock: this.profileDetail.maximumTimeToLock,

                // Application Config Variable
                profile_content_path: "",
                auto_update_mode: "",

                //Kiosk Setting
                kiosk_type: this.profileDetail.kiosk_type,
                kiosk_power_button_action: this.profileDetail.kiosk_power_button_action,
                kiosk_system_app_warning: this.profileDetail.kiosk_system_app_warning,
                kiosk_system_navigation: this.profileDetail.kiosk_system_navigation,
                kiosk_status_bar: this.profileDetail.kiosk_status_bar,
                kiosk_device_setting: this.profileDetail.kiosk_device_setting,

                //System Update
                system_update_type: this.profileDetail.system_update_type,
                system_update_freeze_start_date: this.profileDetail.system_update_freeze_start_date,
                system_update_freeze_end_date: this.profileDetail.system_update_freeze_end_date,

                //System Update
                location_mode: this.profileDetail.location_mode,

                //Samsung Device Settings
                enable_knox_settings: this.profileDetail.enable_knox_settings,
                show_activity_log: this.profileDetail.show_activity_log,
                stop_incoming_call: this.profileDetail.stop_incoming_call,
                stop_incoming_sms: this.profileDetail.stop_incoming_sms,
                disable_multi_window_mode: this.profileDetail.disable_multi_window_mode,
                disable_firmware_update: this.profileDetail.disable_firmware_update,
                hide_status_bar: this.profileDetail.hide_status_bar,
                disable_over_the_air: this.profileDetail.disable_over_the_air,
                disable_recent_key: this.profileDetail.disable_recent_key,
                disable_home_key: this.profileDetail.disable_home_key,
                disable_back_key: this.profileDetail.disable_back_key,
                disable_power_button: this.profileDetail.disable_power_button,
                disable_volumne_up_key: this.profileDetail.disable_volumne_up_key,
                disable_volumne_down_key: this.profileDetail.disable_volumne_down_key,
                apk_list: this.profileDetail.apk_list,
                deactivate_app_list: this.profileDetail.deactivate_app_list,

                lockScreenTimeOut: this.lockScreenTimeOut,
                parentMessage: this.parentMessage,
            };

            await ProfileService.updateProfile(data)
                .then((response) => {
                    this.isDisabled = false;
                    this.$toast.success(response.data.respones_msg);
                }).catch((e) => {
                    this.isDisabled = false;
                    this.$toast.error(e.response.data.respones_msg);
                });
        },
        async redirectBack() {
            await this.updateProfile();
            return this.$router.push({ path: '/home', query: { profileId: this.profileId }});
        }
    },
    beforeMount() {
        this.getDeviceProfileDetail();
    },
}
</script>
