<template>
<div class="container">
    <div class="mb-3">
        <div style="float: left">
            <a href="javascript:void(0)" @click="redirectBack()"><i class="fa fa-fw fa-arrow-left"></i></a>
        </div>
        <div style="margin: 0 auto; width: 100px;"><h5><b>Contacts</b></h5></div>
    </div><hr>
    <div class="row">
        <div class="col-md-12 col-xl-12"> 
            <div class="form-group">
                <input type="text" v-model="name" autofocus class="form-control" placeholder="Enter Name">
            </div>
            <div class="form-group">
                <input type="text" v-model="email" class="form-control" placeholder="Enter Email">
            </div>
            <div class="form-group">
                <input type="text" v-model="phone" class="form-control" placeholder="Enter Phone Number">
            </div>
            <div class="form-group">
                <textarea class="form-control" v-model="notes" placeholder="Enter Notes"></textarea>
            </div>
        </div>
        <div class="col-md-12 text-center mb-3"> 
            <button type="button" class="btn btn-primary w-100" @click="addContact()" :disabled="isDisabled">Add</button>
        </div>
    </div>
  <div class="row">
    <div class="col-md-12 col-xl-12"> 
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Notes</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="contact in contacts" :key="contact._id">
                    <td>
                        {{ contact.name }} <br>
                        {{ contact.email }}
                    </td>
                    <td>{{ contact.phone }}</td>
                    <td>{{ contact.notes }}</td>
                    <td><a href="javascript:void(0)" @click="deleteContact(contact._id)"><i class="fa fa-fw fa-trash text-danger"></i></a></td>
                </tr>
            </tbody>
        </table>
	</div> 
  </div>

</div>
</template>

<script>
import ProfileService from "../services/ProfileService";
export default {
  name: 'Settings',
    data() {
        return {
            isDisabled: false,
            profileId: this.$route.query.profileId,
            name: "",
            email:"",
            phone: "",
            notes: "",
            contacts: [],
        }
    },
    methods: {
        async addContact() {
            this.isDisabled = true;
            let data = {
                profile_id: this.profileId,
                name: this.name,
                email: this.email,
                phone: this.phone,
                notes: this.notes,
            }
            await ProfileService.addContact(data)
            .then((response) => {
                this.isDisabled = false;
                this.name = ""; this.email = ""; this.phone = ""; this.notes = "";
                this.$toast.success(response.data.respones_msg);
                this.getContacts();
            }).catch((e) => {
                this.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
            });    
        },
        async getContacts() {
            let data = {
                profileId: this.profileId,
            }
            await ProfileService.listContact(data)
            .then((response) => {
                this.contacts = response.data.data;
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });    
        },
        async deleteContact(id) {
            let result = confirm("Are you sure you wont to delete this contact?");
            if (result === true) {
                let data = {
                    id: id,
                }
                await ProfileService.deleteContact(data)
                .then((response) => {
                    this.$toast.success(response.data.respones_msg);
                    this.getContacts();
                }).catch((e) => {
                    this.$toast.error(e.response.data.respones_msg);
                });   
            }    
        },
        async redirectBack() {
            return this.$router.push({ path: '/home', query: { profileId: this.profileId }});
        }
    },
    beforeMount() {
        this.getContacts();
    },
}
</script>
