<template>
<div class="container">
    <div class="mb-3">
        <div style="float: left">
            <a href="javascript:void(0)" @click="$router.push({ path: '/home', query: { profileId: profileId }})"><i class="fa fa-fw fa-arrow-left"></i></a>
        </div>
        <div style="margin: 0 auto; width: 150px;"><h5><b>Screen Time</b></h5></div>
    </div><hr>
  <div class="row">
	<div class="col-md-12 col-xl-12 mb-2"> 
		<ul class="list-group">
		  <li class="d-flex justify-content-between align-items-center">
			<span><b>Scheduled</b>
            <br><small>Scheduled turns on downtime for the time period you select.</small>
            </span>
			<span class="float-right">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="enabledScreenTime">
				  <label class="custom-control-label" for="customSwitch1"></label>
				</div>
			</span>
			</li>
		</ul>
	</div>
    <div class="col-md-12 text-left"> 
        <div class="row mb-3">
            <div class="col">
                <select class="form-control" v-model="timezone">
                    <option v-for="timezone in timezoneArray()" :key="timezone" :value="timezone">{{ timezone }}</option>
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <div class="form-group">
                    <input type="text" v-model="customMessage" class="form-control"  placeholder="Enter message">
                </div>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="scrrenTimeType" value="everyDay" name="flexRadioDefault" id="flexRadioDefault1">
            <label class="form-check-label" for="flexRadioDefault1">
                Every Day
            </label>
        </div>
        <form :style="{ 'padding':'15px' }" v-if="scrrenTimeType == 'everyDay'">
            <div class="row">
                <div class="col">
                    <b>Start Time</b><input type="time" class="form-control" v-model="startTime" step="600">
                </div>
                <div class="col">
                    <b>End Time</b><input type="time" class="form-control" v-model="endTime" step="600">
                </div>
            </div>
        </form>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="scrrenTimeType" value="custom" name="flexRadioDefault" id="flexRadioDefault2">
            <label class="form-check-label" for="flexRadioDefault2">
                Custom
            </label>
        </div>
        <form :style="{ 'padding':'15px' }">
            <div class="row" v-for="day in daysArray" :key="day">
                <div class="col">
                    <div class="form-group">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" :value="day" :id="day" v-model="customDays">
                            <label :style="{ 'font-size': '15px' }" :for="day">
                                {{ day }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <input type="time" :style="{ 'font-size': '12px' }" :id="day+'StartTime'" step="600">
                </div>
                <div class="col">
                    <input type="time" :style="{ 'font-size': '12px' }" :id="day+'EndTime'" step="600">
                </div>
            </div>
        </form>
    </div>
	<div class="col-md-12 text-center m-2"> 
		<button type="button" class="btn btn-primary w-100" :disabled="isDisabled" @click="updateScreenTimeSetting()">
            Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>
	</div>
  </div>

</div>
</template>

<script>
import ProfileService from "../services/ProfileService";
export default {
  name: 'Screen Time',
  data() {
    return {
        isDisabled: false,
        profileId: this.$route.query.profileId,
        enabledScreenTime: true,
        scrrenTimeType: "",
        customDays: [],
        daysArray: ['Sunday', 'Monday', 'Tuesday', 'Wed', 'Thursday', 'Friday', 'Saturday'],
        startTime: "",
        endTime: "",
        profileDetail: null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  },
  methods: {
    async updateScreenTimeSetting() {
        let customArray = [];
        let hasError = false;
        if (this.scrrenTimeType == "") {
            return this.$toast.error("Please select screen lock type");
        }
        if (this.scrrenTimeType == "custom") {
            if (this.customDays.length == 0) {
                return this.$toast.error("Please select atleast one days");
            }
            this.customDays.forEach((day, index) => {
                let startTime = document.getElementById(day+"StartTime").value;
                let endTime = document.getElementById(day+"EndTime").value;
                if (!startTime || !endTime) {
                    this.$toast.error("Please select start and end time for " + day);
                    hasError = true;
                }
                customArray.push({
                    day: day,
                    startTime: startTime,
                    endTime: endTime,
                })
            })
        }
        if (hasError) {
            return false;
        }
        if (this.scrrenTimeType == "everyDay") {
            if (!this.startTime || !this.endTime) {
                return this.$toast.error("Please select start and end time.");   
            }
        }
        this.isDisabled = true;
        let data = {
            profileId: this.profileId,
            timezone: this.timezone,
            enabledScreenTime: this.enabledScreenTime,
            scrrenTimeType: this.scrrenTimeType,
            startTime: this.startTime,
            endTime: this.endTime,
            customDays: customArray,
            customMessage: this.customMessage
        }
        await ProfileService.updateScreenTimeSetting(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
        
    },
    async getDeviceProfileDetail() {
      await ProfileService.getDeviceProfileDetail({profile_id: this.profileId})
        .then((response) => {
            this.profileDetail = response.data.data;
            //this.timezone = this.profileDetail.timezone;
            this.customMessage = this.profileDetail.customMessage;
            this.enabledScreenTime = this.profileDetail.enabledScreenTime;
            this.scrrenTimeType = this.profileDetail.scrrenTimeType;
            this.startTime = this.profileDetail.startTime;
            this.endTime = this.profileDetail.endTime;
            if (this.scrrenTimeType == "custom") {
                let customDays = [];
                for (let day of this.profileDetail.customDays) {
                    customDays.push(day.day);
                    let dayName = day.day;
                    document.getElementById(dayName+"StartTime").value = day.startTime;
                    document.getElementById(dayName+"EndTime").value = day.endTime;
                }
                this.customDays = customDays;
            }
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        }); 
    },
    timezoneArray() {
        return [
            'Europe/Andorra',
            'Asia/Dubai',
            'Asia/Kabul',
            'Europe/Tirane',
            'Asia/Yerevan',
            'Antarctica/Casey',
            'Antarctica/Davis',
            'Antarctica/DumontDUrville',
            'Antarctica/Mawson',
            'Antarctica/Palmer',
            'Antarctica/Rothera',
            'Antarctica/Syowa',
            'Antarctica/Troll',
            'Antarctica/Vostok',
            'America/Argentina/Buenos_Aires',
            'America/Argentina/Cordoba',
            'America/Argentina/Salta',
            'America/Argentina/Jujuy',
            'America/Argentina/Tucuman',
            'America/Argentina/Catamarca',
            'America/Argentina/La_Rioja',
            'America/Argentina/San_Juan',
            'America/Argentina/Mendoza',
            'America/Argentina/San_Luis',
            'America/Argentina/Rio_Gallegos',
            'America/Argentina/Ushuaia',
            'Pacific/Pago_Pago',
            'Europe/Vienna',
            'Australia/Lord_Howe',
            'Antarctica/Macquarie',
            'Australia/Hobart',
            'Australia/Currie',
            'Australia/Melbourne',
            'Australia/Sydney',
            'Australia/Broken_Hill',
            'Australia/Brisbane',
            'Australia/Lindeman',
            'Australia/Adelaide',
            'Australia/Darwin',
            'Australia/Perth',
            'Australia/Eucla',
            'Asia/Baku',
            'America/Barbados',
            'Asia/Dhaka',
            'Europe/Brussels',
            'Europe/Sofia',
            'Atlantic/Bermuda',
            'Asia/Brunei',
            'America/La_Paz',
            'America/Noronha',
            'America/Belem',
            'America/Fortaleza',
            'America/Recife',
            'America/Araguaina',
            'America/Maceio',
            'America/Bahia',
            'America/Sao_Paulo',
            'America/Campo_Grande',
            'America/Cuiaba',
            'America/Santarem',
            'America/Porto_Velho',
            'America/Boa_Vista',
            'America/Manaus',
            'America/Eirunepe',
            'America/Rio_Branco',
            'America/Nassau',
            'Asia/Thimphu',
            'Europe/Minsk',
            'America/Belize',
            'America/St_Johns',
            'America/Halifax',
            'America/Glace_Bay',
            'America/Moncton',
            'America/Goose_Bay',
            'America/Blanc-Sablon',
            'America/Toronto',
            'America/Nipigon',
            'America/Thunder_Bay',
            'America/Iqaluit',
            'America/Pangnirtung',
            'America/Atikokan',
            'America/Winnipeg',
            'America/Rainy_River',
            'America/Resolute',
            'America/Rankin_Inlet',
            'America/Regina',
            'America/Swift_Current',
            'America/Edmonton',
            'America/Cambridge_Bay',
            'America/Yellowknife',
            'America/Inuvik',
            'America/Creston',
            'America/Dawson_Creek',
            'America/Fort_Nelson',
            'America/Vancouver',
            'America/Whitehorse',
            'America/Dawson',
            'Indian/Cocos',
            'Europe/Zurich',
            'Africa/Abidjan',
            'Pacific/Rarotonga',
            'America/Santiago',
            'America/Punta_Arenas',
            'Pacific/Easter',
            'Asia/Shanghai',
            'Asia/Urumqi',
            'America/Bogota',
            'America/Costa_Rica',
            'America/Havana',
            'Atlantic/Cape_Verde',
            'America/Curacao',
            'Indian/Christmas',
            'Asia/Nicosia',
            'Asia/Famagusta',
            'Europe/Prague',
            'Europe/Berlin',
            'Europe/Copenhagen',
            'America/Santo_Domingo',
            'Africa/Algiers',
            'America/Guayaquil',
            'Pacific/Galapagos',
            'Europe/Tallinn',
            'Africa/Cairo',
            'Africa/El_Aaiun',
            'Europe/Madrid',
            'Africa/Ceuta',
            'Atlantic/Canary',
            'Europe/Helsinki',
            'Pacific/Fiji',
            'Atlantic/Stanley',
            'Pacific/Chuuk',
            'Pacific/Pohnpei',
            'Pacific/Kosrae',
            'Atlantic/Faroe',
            'Europe/Paris',
            'Europe/London',
            'Asia/Tbilisi',
            'America/Cayenne',
            'Africa/Accra',
            'Europe/Gibraltar',
            'America/Godthab',
            'America/Danmarkshavn',
            'America/Scoresbysund',
            'America/Thule',
            'Europe/Athens',
            'Atlantic/South_Georgia',
            'America/Guatemala',
            'Pacific/Guam',
            'Africa/Bissau',
            'America/Guyana',
            'Asia/Hong_Kong',
            'America/Tegucigalpa',
            'America/Port-au-Prince',
            'Europe/Budapest',
            'Asia/Jakarta',
            'Asia/Pontianak',
            'Asia/Makassar',
            'Asia/Jayapura',
            'Europe/Dublin',
            'Asia/Jerusalem',
            'Asia/Kolkata',
            'Asia/Calcutta',
            'Indian/Chagos',
            'Asia/Baghdad',
            'Asia/Tehran',
            'Atlantic/Reykjavik',
            'Europe/Rome',
            'America/Jamaica',
            'Asia/Amman',
            'Asia/Tokyo',
            'Africa/Nairobi',
            'Asia/Bishkek',
            'Pacific/Tarawa',
            'Pacific/Enderbury',
            'Pacific/Kiritimati',
            'Asia/Pyongyang',
            'Asia/Seoul',
            'Asia/Almaty',
            'Asia/Qyzylorda',
            'Asia/Qostanay',
            'Asia/Aqtobe',
            'Asia/Aqtau',
            'Asia/Atyrau',
            'Asia/Oral',
            'Asia/Beirut',
            'Asia/Colombo',
            'Africa/Monrovia',
            'Europe/Vilnius',
            'Europe/Luxembourg',
            'Europe/Riga',
            'Africa/Tripoli',
            'Africa/Casablanca',
            'Europe/Monaco',
            'Europe/Chisinau',
            'Pacific/Majuro',
            'Pacific/Kwajalein',
            'Asia/Yangon',
            'Asia/Ulaanbaatar',
            'Asia/Hovd',
            'Asia/Choibalsan',
            'Asia/Macau',
            'America/Martinique',
            'Europe/Malta',
            'Indian/Mauritius',
            'Indian/Maldives',
            'America/Mexico_City',
            'America/Cancun',
            'America/Merida',
            'America/Monterrey',
            'America/Matamoros',
            'America/Mazatlan',
            'America/Chihuahua',
            'America/Ojinaga',
            'America/Hermosillo',
            'America/Tijuana',
            'America/Bahia_Banderas',
            'Asia/Kuala_Lumpur',
            'Asia/Kuching',
            'Africa/Maputo',
            'Africa/Windhoek',
            'Pacific/Noumea',
            'Pacific/Norfolk',
            'Africa/Lagos',
            'America/Managua',
            'Europe/Amsterdam',
            'Europe/Oslo',
            'Asia/Kathmandu',
            'Pacific/Nauru',
            'Pacific/Niue',
            'Pacific/Auckland',
            'Pacific/Chatham',
            'America/Panama',
            'America/Lima',
            'Pacific/Tahiti',
            'Pacific/Marquesas',
            'Pacific/Gambier',
            'Pacific/Port_Moresby',
            'Pacific/Bougainville',
            'Asia/Manila',
            'Asia/Karachi',
            'Europe/Warsaw',
            'America/Miquelon',
            'Pacific/Pitcairn',
            'America/Puerto_Rico',
            'Asia/Gaza',
            'Asia/Hebron',
            'Europe/Lisbon',
            'Atlantic/Madeira',
            'Atlantic/Azores',
            'Pacific/Palau',
            'America/Asuncion',
            'Asia/Qatar',
            'Indian/Reunion',
            'Europe/Bucharest',
            'Europe/Belgrade',
            'Europe/Kaliningrad',
            'Europe/Moscow',
            'Europe/Simferopol',
            'Europe/Kirov',
            'Europe/Astrakhan',
            'Europe/Volgograd',
            'Europe/Saratov',
            'Europe/Ulyanovsk',
            'Europe/Samara',
            'Asia/Yekaterinburg',
            'Asia/Omsk',
            'Asia/Novosibirsk',
            'Asia/Barnaul',
            'Asia/Tomsk',
            'Asia/Novokuznetsk',
            'Asia/Krasnoyarsk',
            'Asia/Irkutsk',
            'Asia/Chita',
            'Asia/Yakutsk',
            'Asia/Khandyga',
            'Asia/Vladivostok',
            'Asia/Ust-Nera',
            'Asia/Magadan',
            'Asia/Sakhalin',
            'Asia/Srednekolymsk',
            'Asia/Kamchatka',
            'Asia/Anadyr',
            'Asia/Riyadh',
            'Pacific/Guadalcanal',
            'Indian/Mahe',
            'Africa/Khartoum',
            'Europe/Stockholm',
            'Asia/Singapore',
            'America/Paramaribo',
            'Africa/Juba',
            'Africa/Sao_Tome',
            'America/El_Salvador',
            'Asia/Damascus',
            'America/Grand_Turk',
            'Africa/Ndjamena',
            'Indian/Kerguelen',
            'Asia/Bangkok',
            'Asia/Dushanbe',
            'Pacific/Fakaofo',
            'Asia/Dili',
            'Asia/Ashgabat',
            'Africa/Tunis',
            'Pacific/Tongatapu',
            'Europe/Istanbul',
            'America/Port_of_Spain',
            'Pacific/Funafuti',
            'Asia/Taipei',
            'Europe/Kiev',
            'Europe/Uzhgorod',
            'Europe/Zaporozhye',
            'Pacific/Wake',
            'America/New_York',
            'America/Detroit',
            'America/Kentucky/Louisville',
            'America/Kentucky/Monticello',
            'America/Indiana/Indianapolis',
            'America/Indiana/Vincennes',
            'America/Indiana/Winamac',
            'America/Indiana/Marengo',
            'America/Indiana/Petersburg',
            'America/Indiana/Vevay',
            'America/Chicago',
            'America/Indiana/Tell_City',
            'America/Indiana/Knox',
            'America/Menominee',
            'America/North_Dakota/Center',
            'America/North_Dakota/New_Salem',
            'America/North_Dakota/Beulah',
            'America/Denver',
            'America/Boise',
            'America/Phoenix',
            'America/Los_Angeles',
            'America/Anchorage',
            'America/Juneau',
            'America/Sitka',
            'America/Metlakatla',
            'America/Yakutat',
            'America/Nome',
            'America/Adak',
            'Pacific/Honolulu',
            'America/Montevideo',
            'Asia/Samarkand',
            'Asia/Tashkent',
            'America/Caracas',
            'Asia/Ho_Chi_Minh',
            'Pacific/Efate',
            'Pacific/Wallis',
            'Pacific/Apia',
            'Africa/Johannesburg'
            ];              
    }
  }, 
  watch: {
    scrrenTimeType() {
        if (this.scrrenTimeType != "") {
            this.enabledScreenTime = true;
        }
    },
    enabledScreenTime() {
        if (this.enabledScreenTime == false) {
            this.scrrenTimeType = "";
        } else {

        }
    },
    startTime() {
        let [hours, minutes] = this.startTime.split(":");
        minutes = (Math.ceil(minutes / 10) * 10);
        if (minutes == 0) minutes = "00";
        if (minutes == 60) { minutes = "00"; ++hours % 24; }
            
        let newValue = hours + ":" + minutes;
        this.startTime = newValue
    },
    endTime() {
        let [hours, minutes] = this.endTime.split(":");
        minutes = (Math.ceil(minutes / 10) * 10);
        if (minutes == 0) minutes = "00";
        if (minutes == 60) { minutes = "00"; ++hours % 24; }
            
        let newValue = hours + ":" + minutes;
        this.endTime = newValue
    }
  },
  mounted: function () {
    this.$nextTick(function () {
        this.getDeviceProfileDetail();
    });
    window.onload = function () {
        let daysArray = ['Sunday', 'Monday', 'Tuesday', 'Wed', 'Thursday', 'Friday', 'Saturday'];
        for(let day of daysArray) {
            let myTimepicker = document.getElementById(day+"StartTime");
            myTimepicker.addEventListener("change", function() {
                let [hours, minutes] = myTimepicker.value.split(":");
                
                minutes = (Math.ceil(minutes / 10) * 10);
                if (minutes == 0) minutes = "00";
                if (minutes == 60) { minutes = "00"; ++hours % 24; }
                    
                let newValue = hours + ":" + minutes;
                myTimepicker.value = newValue;
            });

            let myEndTimepicker = document.getElementById(day+"EndTime");
            myEndTimepicker.addEventListener("change", function() {
                let [hours, minutes] = myEndTimepicker.value.split(":");
                
                minutes = (Math.ceil(minutes / 10) * 10);
                if (minutes == 0) minutes = "00";
                if (minutes == 60) { minutes = "00"; ++hours % 24; }
                    
                let newValue = hours + ":" + minutes;
                myEndTimepicker.value = newValue;
            });
        }
    }
  },
}
</script>
