<template>
<div class="container">
    <div class="mb-3">
        <div style="float: left">
            <a href="javascript:void(0)" @click="redirectBack()"><i class="fa fa-fw fa-arrow-left"></i></a>
        </div>
        <div style="float: right">
            <a href="javascript:void(0)" @click="updateProfile()"><i class="fa  fa-floppy-o text-success" title="Lock Screen"></i>&nbsp;Save</a>
        </div>
        <div style="margin: 0 auto; width: 100px;"><h5><b>Restrictions</b></h5></div>
    </div><hr>
    <div class="row">
        <div class="col-md-12 col-xl-12"> 
            <ul class="list-group app-list">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span><b>Disable Outgoing Calls</b></span>
                    <span class="float-right">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="outgoing_calls_disabled" v-model="outgoing_calls_disabled">
                            <label class="custom-control-label" for="outgoing_calls_disabled"></label>
                        </div>
                    </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span><b>Disable SMS</b></span>
                    <span class="float-right">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" v-model="sms_disabled" id="sms_disabled" >
                            <label class="custom-control-label" for="sms_disabled"></label>
                        </div>
                    </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable Bluetooth</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="bluetooth_disabled" id="bluetooth_disabled" >
                                <label class="custom-control-label" for="bluetooth_disabled"></label>
                            </div>
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable Android Beam</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="outgoing_beam_disabled" id="outgoing_beam_disabled" >
                                <label class="custom-control-label" for="outgoing_beam_disabled"></label>
                            </div>
                        </span>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable Network Changes</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="mobile_networks_config_disabled" id="mobile_networks_config_disabled" >
                                <label class="custom-control-label" for="mobile_networks_config_disabled"></label>
                            </div>
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable Tethering</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="tethering_config_disabled" id="tethering_config_disabled" >
                                <label class="custom-control-label" for="tethering_config_disabled"></label>
                            </div>
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable WiFi Changes</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="wifi_config_disabled" id="wifi_config_disabled" >
                                <label class="custom-control-label" for="wifi_config_disabled"></label>
                            </div>
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable Screen Capture</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="screen_capture_disabled" id="screen_capture_disabled" >
                                <label class="custom-control-label" for="screen_capture_disabled"></label>
                            </div>
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable Camera</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="camera_disabled" id="camera_disabled" >
                                <label class="custom-control-label" for="camera_disabled"></label>
                            </div>
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable Developer Setting</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="developer_settings" id="developer_settings" >
                                <label class="custom-control-label" for="developer_settings"></label>
                            </div>
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Installation From Unkown Source</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="install_unknown_sources_allowed" id="install_unknown_sources_allowed" >
                                <label class="custom-control-label" for="install_unknown_sources_allowed"></label>
                            </div>
                        </span>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable Keyguard</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="keyguard_disabled" id="keyguard_disabled" >
                                <label class="custom-control-label" for="keyguard_disabled"></label>
                            </div>
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable Notification Bar</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="status_bar_disabled" id="status_bar_disabled" >
                                <label class="custom-control-label" for="status_bar_disabled"></label>
                            </div>
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><b>Disable Volume</b></span>
                        <span class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" v-model="adjust_volume_disabled" id="adjust_volume_disabled" >
                                <label class="custom-control-label" for="adjust_volume_disabled"></label>
                            </div>
                        </span>
                    </li>
                </ul>
        </div>
    </div>
    
</div>
</template>

<script>
import ProfileService from "../services/ProfileService";
export default {
  name: 'Restrictions',
  data() {
    return {
        isDisabled: true,
        profileId: this.$route.query.profileId,
        profileDetail: null,
        outgoing_calls_disabled: false,
        sms_disabled: false,
        bluetooth_disabled: false,
        outgoing_beam_disabled: false,
        mobile_networks_config_disabled: false,
        tethering_config_disabled: false,
        wifi_config_disabled: false,
        screen_capture_disabled: false,
        camera_disabled: false,
        developer_settings: false,
        install_unknown_sources_allowed: false,
        keyguard_disabled: false,
        status_bar_disabled: false,
        adjust_volume_disabled: false,
    }
  },
  methods: {
    getDeviceProfileDetail() {
      ProfileService.getDeviceProfileDetail({profile_id: this.profileId})
        .then((response) => {
            this.profileDetail = response.data.data;
            this.outgoing_calls_disabled = response.data.data.outgoing_calls_disabled;
            this.sms_disabled = response.data.data.sms_disabled;
            this.bluetooth_disabled = response.data.data.bluetooth_disabled;
            this.outgoing_beam_disabled = response.data.data.outgoing_beam_disabled;
            this.add_user_disabled = response.data.data.add_user_disabled;
            this.remove_user_disabled = response.data.data.remove_user_disabled;
            this.modify_accounts_disabled = response.data.data.modify_accounts_disabled;
            this.mobile_networks_config_disabled = response.data.data.mobile_networks_config_disabled;
            this.tethering_config_disabled = response.data.data.tethering_config_disabled;
            this.wifi_config_disabled = response.data.data.wifi_config_disabled;
            this.screen_capture_disabled = response.data.data.screen_capture_disabled;
            this.camera_disabled = response.data.data.camera_disabled;
            this.keyguard_disabled = response.data.data.keyguard_disabled;
            this.status_bar_disabled = response.data.data.status_bar_disabled;
            this.adjust_volume_disabled = response.data.data.adjust_volume_disabled;
            this.package_name = response.data.data.single_app_kiosk_package_name;
            this.developer_settings = response.data.data.developer_settings;
            this.install_unknown_sources_allowed = response.data.data.install_unknown_sources_allowed;
            this.safe_boot_disabled = response.data.data.safe_boot_disabled;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    async updateProfile() {
      this.isDisabled = true;
      var data = {
        profile_id: this.profileId,
        profile_name: this.profileDetail.profile_name,
        outgoing_calls_disabled: this.outgoing_calls_disabled,
        sms_disabled: this.sms_disabled,
        bluetooth_disabled: this.bluetooth_disabled,
        outgoing_beam_disabled: this.outgoing_beam_disabled,
        add_user_disabled: this.profileDetail.add_user_disabled,
        remove_user_disabled: this.profileDetail.remove_user_disabled,
        modify_accounts_disabled: this.profileDetail.modify_accounts_disabled,
        mobile_networks_config_disabled: this.mobile_networks_config_disabled,
        tethering_config_disabled: this.tethering_config_disabled,
        wifi_config_disabled: this.wifi_config_disabled,
        screen_capture_disabled: this.screen_capture_disabled,
        camera_disabled: this.camera_disabled,
        keyguard_disabled: this.keyguard_disabled,
        status_bar_disabled: this.status_bar_disabled,
        adjust_volume_disabled: this.adjust_volume_disabled,
        install_type: "",
        developer_settings: this.developer_settings,
        install_unknown_sources_allowed: this.install_unknown_sources_allowed,
        safe_boot_disabled: this.profileDetail.safe_boot_disabled,

        // Password Policy
        password_type: this.profileDetail.passwordQuality,
        min_password_length: this.profileDetail.passwordMinimumLength,
        min_number_of_symbols: this.profileDetail.passwordMinimumSymbols,
        min_number_of_alphabets: this.profileDetail.passwordMinimumLetters,
        min_number_of_lower_case_characters: this.profileDetail.passwordMinimumLowerCase,
        min_number_of_upper_case_characters: this.profileDetail.passwordMinimumUpperCase,
        min_number_of_digits: this.profileDetail.passwordMinimumNumeric,
        max_password_history_list: this.profileDetail.passwordHistoryLength,
        max_failed_attempt_to_factory_reset: this.profileDetail.maximumFailedPasswordsForWipe,
        password_expiry_period: this.profileDetail.passwordExpirationTimeout,
        idle_time_for_auto_lock: this.profileDetail.maximumTimeToLock,

        // Application Config Variable
        profile_content_path: "",
        auto_update_mode: "",

        //Kiosk Setting
        kiosk_type: this.profileDetail.kiosk_type,
        kiosk_power_button_action: this.profileDetail.kiosk_power_button_action,
        kiosk_system_app_warning: this.profileDetail.kiosk_system_app_warning,
        kiosk_system_navigation: this.profileDetail.kiosk_system_navigation,
        kiosk_status_bar: this.profileDetail.kiosk_status_bar,
        kiosk_device_setting: this.profileDetail.kiosk_device_setting,

        //System Update
        system_update_type: this.profileDetail.system_update_type,
        system_update_freeze_start_date: this.profileDetail.system_update_freeze_start_date,
        system_update_freeze_end_date: this.profileDetail.system_update_freeze_end_date,

        //System Update
        location_mode: this.profileDetail.location_mode,

        //Samsung Device Settings
        enable_knox_settings: this.profileDetail.enable_knox_settings,
        show_activity_log: this.profileDetail.show_activity_log,
        stop_incoming_call: this.profileDetail.stop_incoming_call,
        stop_incoming_sms: this.profileDetail.stop_incoming_sms,
        disable_multi_window_mode: this.profileDetail.disable_multi_window_mode,
        disable_firmware_update: this.profileDetail.disable_firmware_update,
        hide_status_bar: this.profileDetail.hide_status_bar,
        disable_over_the_air: this.profileDetail.disable_over_the_air,
        disable_recent_key: this.profileDetail.disable_recent_key,
        disable_home_key: this.profileDetail.disable_home_key,
        disable_back_key: this.profileDetail.disable_back_key,
        disable_power_button: this.profileDetail.disable_power_button,
        disable_volumne_up_key: this.profileDetail.disable_volumne_up_key,
        disable_volumne_down_key: this.profileDetail.disable_volumne_down_key,
        apk_list: this.profileDetail.apk_list,
        deactivate_app_list: this.profileDetail.deactivate_app_list,
      };

      await ProfileService.updateProfile(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async redirectBack() {
        await this.updateProfile();
        return this.$router.push({ path: '/home', query: { profileId: this.profileId }});
    }
  },
  beforeMount() {
    this.getDeviceProfileDetail();
  },
}
</script>
