<template>
    <div class="container">
        <div class="mb-3">
            <div style="float: left">
                <img :src="topLeftImage" :style="{ 'height': '30px', 'width': '30px' }" />
            </div>
            <div style="float: right">
                <button type="button" class="btn btn-primary" @click="applyPolicy()" :disabled="isDisabled">Apply <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>&nbsp;
                <a href="javascript:void(0)" @click="showSearchSection = true"><i class="fa fa-fw fa-search"></i></a>
            </div>
            <div style="margin: 0 auto; width: 100px;"><h5><b>Settings</b></h5></div>
        </div><hr>
        <div class="row">
            <div class="col-md-12 col-xl-12" v-if="showSearchSection">  
                <div class="input-group mb-3">
                    <input type="text" class="form-control" v-model="profileName" placeholder="Enter kids name for search">
                    <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" @click="getDeviceProfileList()" :disabled="isDisabled">
                        <i class="fa fa-search"></i>
                    </button>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-xl-12 kids-list">                       
                <a href="javascript:void(0)" @click="switchProfile(profile)" v-for="profile in profileList" :key="profile._id">
                    <div class="text-center">
                        <div class="align-items-center">
                        <span style="background-image: url(https://bootdey.com/img/Content/avatar/avatar6.png)" class="avatar avatar-xl mr-1" :class="getActiveClass(profile._id)"></span>
                        </div>
                        <span class="card-text mb-0" :class="getActiveClass(profile._id)">{{ profile.profile_name }}</span>
                    </div>
                </a>
            </div>
            <div class="col-md-12 col-xl-12" v-if="currentProfile"> 
                <ul class="list-group scroll-it">
                    <!--<a href="javascript:void(0)" @click="$router.push({ path: '/web', query: { profileId: currentProfile._id }})">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <span><i class="fa fa-fw fa-globe text-danger"></i> <b>Web Settings</b></span>
                            <span class="float-right">ON ></span>
                        </li>
                    </a>-->
                    <a href="javascript:void(0)" @click="$router.push({ path: '/apps', query: { profileId: currentProfile._id }})">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <span><i class="fa fa-fw fa-film text-primary"></i> 
                            <!--<img :src="appListIcon" class="app-icon" /> &nbsp;-->
                            <b> App Settings</b></span>
                            <span class="badge badge-primary badge-pill">ON ></span>
                        </li>
                    </a>
                    <a href="javascript:void(0)" @click="$router.push({ path: '/restrictions', query: { profileId: currentProfile._id }})">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <span><i class="fa fa-fw fa-cogs text-primary"></i> <b>Restrictions</b></span>
                            <span class="badge badge-primary badge-pill">ON ></span>
                        </li>
                    </a>
                    <!--<li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><i class="fa fa-fw fa-commenting-o text-danger"></i> <b>Text Messages</b></span>
                        <span class="badge badge-primary badge-pill">ON ></span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><i class="fa fa-fw fa-phone-square text-danger"></i> <b>Call Settings</b></span>
                        <span class="badge badge-primary badge-pill">ON ></span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span><i class="fa fa-fw fa-clock-o text-danger"></i> <b>Screen Time</b></span>
                        <span class="badge badge-primary badge-pill">ON ></span>
                    </li>-->
                    <a href="javascript:void(0)" @click="$router.push({ path: '/screen-time', query: { profileId: currentProfile._id }})">
                        <li class="list-group-item">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="fa fa-fw fa-clock-o text-primary"></i> <b>Screen Time</b></span>
                                <span class="badge badge-primary badge-pill">ON ></span> 
                            </div>
                        </li>
                    </a>
                    <li class="list-group-item">
                        <div class=" d-flex justify-content-between align-items-center">
                            <span><i class="fa fa-fw fa-tablet text-primary"></i> <b>Total Devices</b></span>
                            <span class="float-right">{{ currentProfile.totalDevices }}</span>  
                        </div>
                        <div>
                            <a href="javascript:void(0)" @click="$router.push({ path: '/device', query: { profileId: currentProfile._id, id: device._id }})" v-for="device in deviceList" :key="device._id"><small>{{ device.serialNumber }}</small>&nbsp;&nbsp;</a>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class=" d-flex justify-content-between">
                            <span><i class="fa fa-fw fa-lock text-primary"></i> <b>Security</b></span> 
                        </div>
                        <div class="text-left m-3">
                            <label class="text-primary fw-bold">Strict Mode: &nbsp;</label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="profileStrictMode" v-model="profileStrictMode"  value="low">
                                <label class="form-check-label" for="inlineRadio1">Medium</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="profileStrictMode" v-model="profileStrictMode" value="high">
                                <label class="form-check-label" for="inlineRadio2">High</label>
                            </div>
                        </div>
                    </li>
                    <a href="javascript:void(0)" @click="$router.push({ path: '/contacts', query: { profileId: currentProfile._id }})">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <span><i class="fa fa-fw fa-address-book text-primary"></i> <b>Secure Contacts</b></span>
                        </li>
                    </a>
                    <a href="javascript:void(0)" @click="$router.push({ path: '/secure-browsers', query: { profileId: currentProfile._id }})">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <span><i class="fa fa-fw fa-chrome text-primary"></i> <b>Secure Browser</b></span>
                        </li>
                    </a>
                    <a href="javascript:void(0)" @click="$router.push({ path: '/wipe', query: { profileId: currentProfile._id }})">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <span><i class="fa fa-fw fa-bug text-primary"></i> <b>Wipe</b></span>
                        </li>
                    </a>
                    <a href="javascript:void(0)" @click="$router.push({ path: '/secure-chat', query: { profileId: currentProfile._id }})">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <span><i class="fa fa-fw fa-commenting text-primary"></i> <b>Secure Chat</b></span>
                        </li>
                    </a>
                    <a href="javascript:void(0)" @click="$router.push({ path: '/settings', query: { profileId: currentProfile._id }})">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <span><i class="fa fa-fw fa-cog text-primary"></i> <b>Settings</b></span>
                        </li>
                    </a>
                </ul>
            </div>
            <!--<div class="col-md-12 text-center m-2" v-if="currentProfile"> 
                <button type="button" class="btn btn-primary w-100" :disabled="isDisabled" @click="applyPolicy()">
                    Send To Devices<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                </button>
            </div>-->
            <div class="col-md-12 text-center m-2" v-if="profileId"> 
                <button type="button" class="btn btn-primary w-100" @click="getDeviceProfileList(true)">
                    Home
                </button>
            </div>
            <div class="col-md-12 text-center m-2" v-if="!currentProfile && profileName"> 
                <span>No kids found</span>    
            </div>
            
            <div class="col-md-12 col-xl-12 text-center fixed-bottom mb-3"> 
                <div class="navbar">
                <a class="active text-center" href="#"><i class="fa fa-fw fa-home"></i><br> Home</a> 
                <!--<a href="#" class="text-center"><i class="fa fa-fw fa-cog"></i><br> Settings</a> 
                <a href="#" class="text-center"><i class="fa fa-fw fa-envelope"></i><br> Contacts</a>--> 
                <a href="#" class="text-center"><i class="fa fa-fw fa-question"></i><br> Support</a>
                <a href="#" @click="logout()" class="text-center"><i class="fa fa-fw fa-sign-out"></i><br> Logout</a>
                </div>
            </div>
        </div>

        </div>
</template>

<script>
import ProfileService from "../services/ProfileService";
export default {
  name: 'Home',
  props: {
    topLeftImage: {
        type: String,
        default: "./images/home-top-left.png",
    },
    appListIcon: {
        type: String,
        default: "./images/app-list-icon.png",
    },
  },
  data() {
    return {
        isDisabled: false,
        profileList: [],
        currentProfile: null,
        showSearchSection: false,
        profileName:"",
        deviceList: [],
        profileId: this.$route.query.profileId,
        profileStrictMode: "high"
    }
  },
  methods: {
    async getDeviceProfileList(resetProfileId = false) {
        if (resetProfileId == true) {
            this.profileId = "";
            this.$router.replace('/home')
        }
      let data = {
        profileId: (this.profileName) ? "" : this.profileId,
        search_profile_name: this.profileName,
        search_profile_type: "Android",
        pageSize: 4,
      }
      await ProfileService.getDeviceProfileList(data)
        .then((response) => {
            this.profileList = response.data.data;
            this.currentProfile = this.profileList[0];
            if (this.profileId) {
                this.applyPolicy();
            }
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    switchProfile(profileDetail) {
        this.currentProfile = profileDetail;
    },

    applyPolicy() {
      this.isDisabled = true;
      let data = {
        profile_id: this.currentProfile._id
      };

      ProfileService.applyPolicy(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    async getDeviceList() {
        let data = {
            profile_id: this.currentProfile._id,
        };

        await ProfileService.getDeviceList(data)
            .then((response) => {
                this.deviceList = response.data.data;
                //this.$toast.success(response.data.respones_msg);
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });   
    },


    getActiveClass(profileId) {
        if (profileId == this.currentProfile._id) {
            return "active";
        } else {
            return "";
        }
    },
    logout() {
        localStorage.setItem('token', "");
        this.$router.push('login');
    },
  },
  watch: {
    async currentProfile() {
        if (this.currentProfile) {
            if (this.currentProfile.profileStrictMode) {
                this.profileStrictMode = this.currentProfile.profileStrictMode;
            } else {
                this.profileStrictMode = "high";
            }
            await this.getDeviceList();
        }
    },
    async profileStrictMode() {
        let data = {
            profileId: this.currentProfile._id,
            profileStrictMode: this.profileStrictMode,
        }
        await ProfileService.updateProfileSecuritySetting(data)
            .then((response) => {
                this.$toast.success(response.data.respones_msg);
            }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            });
    }
  },
  beforeMount() {
    this.getDeviceProfileList();
  }, 
}
</script>
