<template>
<div class="container">
    <div class="mb-3">
        <div style="float: left">
            <a href="javascript:void(0)" @click="redirectBack()"><i class="fa fa-fw fa-arrow-left"></i></a>
        </div>
        <div style="margin: 0 auto; width: 100px;"><h5><b>Wipe</b></h5></div>
    </div><hr>
    <div class="row">
        <div class="col-md-12 col-xl-12"> 
            <div class="accordion" id="accordionExample">
                <div class="card">
                    <div class="card-header" id="deleteFilesDirectory">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Delete Files & Folder
                            </button>
                        </h2>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="deleteFilesDirectory" data-parent="#accordionExample">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 col-xl-12"> 
                                    <div class="form-group">
                                        <input type="url" v-model="filePath" autofocus class="form-control" placeholder="Enter File / Folder Name">
                                    </div>
                                </div>
                                <div class="col-md-12 text-center mb-3"> 
                                    <button type="button" class="btn btn-primary w-100" @click="addWipeFiles()" :disabled="isDisabled">Add</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-xl-12"> 
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Path</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="profileDetail">
                                            <tr v-for="path in wipeDeleteFileFolder" :key="path">
                                                <td>{{ path }}</td>
                                                <td><a href="javascript:void(0)" @click="deleteFilePath(path)"><i class="fa fa-fw fa-trash text-danger"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="wipeAppsTab">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Wipe Applications
                            </button>
                        </h2>
                    </div>

                    <div id="collapseOne" class="collapse" aria-labelledby="wipeAppsTab" data-parent="#accordionExample">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 col-xl-12"> 
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" v-model="applicationName" placeholder="Search play store applications">
                                        <div class="input-group-append">
                                            <button class="btn btn-secondary" type="button" @click="searchPlayStore()" :disabled="isDisabled">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="app-listing-copy">
                                        <ul id="product-directory">
                                            <li v-for="application in playStoreApplications" :key="application.appId">
                                                <img :src="application.icon" /><a href="javascript:void(0);" @click="createProfileWipeApplication(application, 'wipe')" class="badge-icon"><i aria-hidden="true" class="fa fa-plus"></i></a>
                                                <div class="app-listing-text">
                                                    <span class="apptext">
                                                        <small><b>{{ application.title }}</b></small>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-12"> 
                                    <div class="app-listing-copy" v-if="profileDetail">
                                        <ul id="product-directory">
                                            <li v-for="application in profileDetail.wipeApplications" :key="application.appId">
                                                <img :src="application.icon" /><a href="javascript:void(0);" @click="deleteWipeProfileApplication(application.appId, 'wipe')" class="badge-icon"><i aria-hidden="true" class="fa fa-trash text-danger"></i></a>
                                                <div class="app-listing-text">
                                                    <span class="apptext">
                                                        <small><b>{{ application.title }}</b></small>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>  

                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="uninstallAppsTab">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                            Uninstall Applications
                            </button>
                        </h2>
                    </div>

                    <div id="collapseTwo" class="collapse" aria-labelledby="uninstallAppsTab" data-parent="#accordionExample">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 col-xl-12"> 
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" v-model="applicationName" placeholder="Search play store applications">
                                        <div class="input-group-append">
                                            <button class="btn btn-secondary" type="button" @click="searchPlayStore()" :disabled="isDisabled">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="app-listing-copy">
                                        <ul id="product-directory">
                                            <li v-for="application in playStoreApplications" :key="application.appId">
                                                <img :src="application.icon" /><a href="javascript:void(0);" @click="createProfileWipeApplication(application, 'uninstall')" class="badge-icon"><i aria-hidden="true" class="fa fa-plus"></i></a>
                                                <div class="app-listing-text">
                                                    <span class="apptext">
                                                        <small><b>{{ application.title }}</b></small>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-12"> 
                                    <div class="app-listing-copy" v-if="profileDetail">
                                        <ul id="product-directory">
                                            <li v-for="application in profileDetail.wipeUninstallApplication" :key="application.appId">
                                                <img :src="application.icon" /><a href="javascript:void(0);" @click="deleteWipeProfileApplication(application.appId, 'uninstall')" class="badge-icon"><i aria-hidden="true" class="fa fa-trash text-danger"></i></a>
                                                <div class="app-listing-text">
                                                    <span class="apptext">
                                                        <small><b>{{ application.title }}</b></small>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>  

                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="otherSettingTab">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                            Other Settings
                            </button>
                        </h2>
                    </div>

                    <div id="collapseThree" class="collapse" aria-labelledby="otherSettingTab" data-parent="#accordionExample">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-xl-6"> 
                                    <label>Clear Cookies</label>
                                </div>
                                <div class="col-md-6 col-xl-6"> 
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="clearCookies" v-model="clearCookies" value="true">
                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="clearCookies"  v-model="clearCookies" value="false">
                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                    </div>
                                </div>
                            </div>  
                            <div class="row">
                                <div class="col-md-6 col-xl-6"> 
                                    <label>Trigger Mode</label>
                                </div>
                                <div class="col-md-6 col-xl-6"> 
                                    <select class="form-control" v-model="triggerMode">
                                        <option value="">Select Trigger Mode</option>
                                        <option value="Reboot">Reboot</option>
                                        <option value="Daily">Daily Wipe</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row text-center justify-center">
                                <div class="col-md-6 p-3"> 
                                    <button type="button" class="btn btn-primary w-100" @click="updateWipeSetting()" :disabled="isDisabled">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ProfileService from "../services/ProfileService";
export default {
  name: 'Wipe',
    data() {
        return {
            isDisabled: false,
            profileId: this.$route.query.profileId,
            filePath: "",
            profileDetail: null,
            wipeDeleteFileFolder: [],

            //Wipe Application
            playStoreApplications: [],
            applicationName: "",
            
            clearCookies: "",
            triggerMode: "",
        }
    },
    methods: {
        async addWipeFiles() {
            this.isDisabled = true;
            let data = {
                profile_id: this.profileId,
                filePath: this.filePath,
            }
            await ProfileService.addWipeFiles(data)
            .then((response) => {
                this.isDisabled = false;
                this.filePath = "";
                this.$toast.success(response.data.respones_msg);
                this.getDeviceProfileDetail();
            }).catch((e) => {
                this.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
            });    
        },
        getDeviceProfileDetail() {
            ProfileService.getDeviceProfileDetail({profile_id: this.profileId})
                .then((response) => {
                    this.profileDetail = response.data.data;
                    this.wipeDeleteFileFolder = response.data.data.wipeDeleteFileFolder.reverse();
                    this.clearCookies = this.profileDetail.wipeClearCookies;
                    this.triggerMode = this.profileDetail.wipeTriggerMode;
                }).catch((e) => {
                    this.$toast.error(e.response.data.respones_msg);
                });
        },
        async deleteFilePath(path) {
            let result = confirm("Are you sure you wont to delete this file?");
            if (result === true) {
                let data = {
                    filePath: path,
                    profile_id: this.profileId,
                }
                await ProfileService.deleteFilePath(data)
                .then((response) => {
                    this.$toast.success(response.data.respones_msg);
                    this.getDeviceProfileDetail();
                }).catch((e) => {
                    this.$toast.error(e.response.data.respones_msg);
                });   
            }    
        },
        async redirectBack() {
            return this.$router.push({ path: '/home', query: { profileId: this.profileId }});
        },

        searchPlayStore() {
            if (!this.applicationName) {
                this.$toast.error("Enter application name for search");
                return;
            }
            this.isDisabled = true;
            let data = {
                app_name: this.applicationName,
                country:"us",
                age:"",
            }
            ProfileService.searchPlayStore(data)
            .then((response) => {
                this.playStoreApplications = response.data.data;
                this.isDisabled = false;
            })
            .catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
                this.isDisabled = false;
            }); 
        },
        async createProfileWipeApplication(app, pdAppType) {
            let masterApplicationData = {
                product_id: app.appId,
                package_name: app.appId,
                app_icon: app.icon,
                application_details: app,
                type: "Android",
            };
            await ProfileService.createMasterApplication(masterApplicationData)
            .then((response) => {
            }).catch((e) => {
            })

        let data = {
            profile_id: this.profileId,
            applicationDetail: app,
            pdAppType: pdAppType
        };

        await ProfileService.createProfileWipeApplication(data)
            .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.getDeviceProfileDetail();
            this.playStoreApplications = [];
            this.applicationName = "";
            })
            .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            });
        },

        async deleteWipeProfileApplication(packageName, pdAppType) {
            let data = {
                profile_id: this.profileId,
                packageName: packageName,
                pdAppType: pdAppType
            };

            await ProfileService.deleteWipeProfileApplication(data)
                .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.getDeviceProfileDetail();
                }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
                });
        },

        async updateWipeSetting() {
            let data = {
                clearCookies: this.clearCookies,
                triggerMode: this.triggerMode,
                profile_id: this.profileId,
            }
            await ProfileService.updateWipeSetting(data)
            .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.getDeviceProfileDetail();
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });    
        },
    },
    beforeMount() {
        this.getDeviceProfileDetail();
    },
}
</script>
