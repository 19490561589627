<template>
<div class="container">
    <div class="mb-3">
        <div style="float: left">
            <a href="javascript:void(0)" @click="$router.push({ path: '/home', query: { profileId: profileId }})"><i class="fa fa-fw fa-arrow-left"></i></a>
        </div>
        <div style="margin: 0 auto; width: 100px;"><h5><b>Web</b></h5></div>
    </div><hr>
  <div class="row">
	<div class="col-md-12 col-xl-12"> 
		<ul class="list-group app-list">
		  <li class="list-group-item d-flex justify-content-between align-items-center">
			<span><b>Internet Access</b></span>
			<span class="float-right">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="customSwitch1" checked>
				  <label class="custom-control-label" for="customSwitch1"></label>
				</div>
			</span>
			</li>
			<li class="list-group-item d-flex justify-content-between align-items-center">
			<span><b>Web Filtering</b></span>
			<span class="float-right">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="customSwitch1" >
				  <label class="custom-control-label" for="customSwitch1"></label>
				</div>
			</span>
			</li>
			<li class="list-group-item d-flex justify-content-between align-items-center">
			<span><b>URL Whitelisting</b></span>
			<span class="float-right">
				>
			</span>
			</li>
			<li class="list-group-item d-flex justify-content-between align-items-center">
			<span><b>Add Kids Safe Browser</b></span>
			<span class="float-right">
				<div class="custom-control custom-switch">
				  <input type="checkbox" class="custom-control-input" id="customSwitch1" >
				  <label class="custom-control-label" for="customSwitch1"></label>
				</div>
			</span>
			</li>
		</ul>
	</div>
	<div class="col-md-12 text-center fixed-bottom mb-3"> 
		<button type="button" class="btn btn-primary w-100">Continue</button>
	</div>
  </div>

</div>
</template>

<script>

export default {
  name: 'Web',
  data() {
    return {
        profileId: this.$route.query.profileId,
    }
  },
}
</script>
