<template>
<div class="container">
    <div class="mb-3">
        <div style="float: left">
            <a href="javascript:void(0)" @click="$router.push({ path: '/home', query: { profileId: profileId }})"><i class="fa fa-fw fa-arrow-left"></i></a>
        </div>
        <div style="margin: 0 auto; width: 100px;"><h5><b>Secure Chat</b></h5></div>
    </div><hr>
    <div class="row">
        <div class="col-md-12 col-xl-12" v-if="!fcmUserDetail">
            <div class="input-group mb-3" >
                <input type="text" class="form-control" v-model="userId" placeholder="Enter user name">
                <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" @click="createPowerChatUser()" :disabled="isDisabled">
                        Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-xl-12" v-else>
            <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                User Name: {{ fcmUserDetail.fcmID }}
                <span><a href="javascript:void(0)" data-toggle="modal" data-target="#loginQrCodeModal"><i class="fa fa-qrcode" title="Login QR Code"></i></a></span>
                </li>
            </ul>
            <!-- Modal -->
            <div class="modal fade" id="loginQrCodeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Login QR Code</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <img :src="loginQRCode" />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-12 col-xl-12 p-2" v-if="fcmUserDetail">
            <ul class="nav nav-pills" role="tablist">
                <li class="nav-item active"><a class="nav-link active" href="#contacts" role="tab" data-toggle="tab">Contacts</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact-request" role="tab" data-toggle="tab">Request <span class="badge badge-danger badge-pill" v-if="fcmUserDetail.requestFrom.length > 0">{{ fcmUserDetail.requestFrom.length }}</span></a></li>
                <li class="nav-item"><a class="nav-link" href="#contact-add" role="tab" data-toggle="tab">Add Contact</a></li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content p-2">
                <div class="tab-pane active" id="contacts">
                    <ul class="list-group text-left">
                        <li class="list-group-item" v-for="contact in contacts" :key="contact">{{ contact }}</li>
                    </ul>
                </div>
                <div class="tab-pane" id="contact-request">.
                    <ul class="list-group text-left" v-if="fcmUserDetail.requestFrom">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="contact in fcmUserDetail.requestFrom" :key="contact">
                            {{ contact }}
                            <span>
                                <a href="javascript:void(0)" @click="approveRequest(contact)"><i class="fa fa-check text-primary"></i> Accept</a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="tab-pane" id="contact-add">
                    <div class="input-group mb-3" >
                        <input type="text" class="form-control" v-model="friendUserId" placeholder="Enter user name">
                        <div class="input-group-append">
                            <button class="btn btn-secondary" type="button" @click="sendRequest()" :disabled="isDisabled">
                                Add<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                            </button>
                        </div>
                    </div>
                    <ul class="list-group text-left" v-if="fcmUserDetail.requestTo">
                        <li class="list-group-item" v-for="contact in fcmUserDetail.requestTo" :key="contact">{{ contact }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ChatService from "../services/ChatService";

export default {
  name: 'Secure Chat',
  data() {
    return {
        isDisabled: false,
        profileId: this.$route.query.profileId,
        fcmUserDetail: null,
        contacts: [],
        loginQRCode: "",
        userId: "",

        // Add Contact
        friendUserId: "",
    }
  },
  methods: {
    getFcmUserDetail() {
        ChatService.getFcmUserDetail({profileId: this.profileId})
            .then((response) => {
                this.fcmUserDetail = response.data.data;
                if (this.fcmUserDetail) {
                    this.loginQRCode = process.env.VUE_APP_API_BASE_URL + "upload/qrCode/fcm/" + this.fcmUserDetail._id + ".png"
                    if (this.fcmUserDetail.fcmData) {
                        this.contacts = this.fcmUserDetail.fcmData.added_by_user.split(',');
                    }
                }
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
    },
    createPowerChatUser() {
        this.isDisabled = true;
        let data = { profileId: this.profileId, userId: this.userId };
        ChatService.createPowerChatUser(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.getFcmUserDetail();
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
        });
    },
    sendRequest() {
        this.isDisabled = true;
        let data = { profileId: this.profileId, friendUserId: this.friendUserId };
        ChatService.sendRequest(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.friendUserId = ""
            this.getFcmUserDetail();
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
        });
    },
    approveRequest(friendUserId) {
        this.isDisabled = true;
        let data = { profileId: this.profileId, friendUserId: friendUserId };
        ChatService.approveRequest(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.getFcmUserDetail();
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
        });
    },
  },
  beforeMount() {
    this.getFcmUserDetail();
},
}
</script>
