<template>
    <div class="container">
        <div style="margin-top:150px">
            <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="this.show_message">
                <strong>Congratulation!</strong> A message with a reset password link has been sent to your email address. Please open the link to set your new password.
            </div><br>
            <img src="https://www.kidfirstphone.com/assets/img/logo-color.png" class="img-fluid text-center" alt="Responsive image"	/><br><br>
            <form>
            <div class="form-group">
                <input type="password" v-model="password" class="form-control" placeholder="Enter password">
            </div>
            <div class="form-group">
                <input type="password" v-model="confirm_password" class="form-control" placeholder="Enter confirm password">
            </div>
            <div class="form-group text-center">
            <button class="btn btn-primary" :disabled="isDisabled" @click="resetPassword()">Change Password
                <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
            </button>
            </div>
            </form>
        </div>
    </div>
</template>

<script>
import UserService from "../services/UserService";
export default {
  name: 'Login',
  data() {
    return {
      isDisabled: false,
      password: null,
      confirm_password: null,
    };
  },
  methods: {
    resetPassword() {
        this.isDisabled = true;
        var data = {
            password: this.password,
            confirm_password: this.confirm_password,
            iv: this.$route.query.iv,
            encrypted_data: this.$route.query.encryptedData
        };
        UserService.resetPassword(data)
            .then((response) => {
                this.isDisabled = false;
                this.$toast.success(response.data.respones_msg);
                this.$router.push('/login');
            }).catch((e) => {
                this.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
            });
    },
  },
  beforeMount() {
    
  },
}
</script>
