<template>
    <div class="container">
        <div class="mb-3">
            <div style="float: left">
                <a href="javascript:void(0)" @click="$router.push({ path: '/home', query: { profileId: profileId }})"><i class="fa fa-fw fa-arrow-left"></i></a>
            </div>
            <div style="float: right">
                <a href="javascript:void(0)" @click="showPlayStoreSection = true"><i class="fa fa-fw fa-plus"></i></a>
            </div>
            <div style="margin: 0 auto; width: 100px;"><h5><b>Apps</b></h5></div>
        </div><hr>
        <div class="row">
            <div class="col-md-12 col-xl-12"> 
                <div class="input-group mb-3">
                    <div class="input-group-append">
                      <select class="form-control" v-model="age">
                        <option value="">All Age</option>
                        <option value="AGE_RANGE1">Under 5</option>
                        <option value="AGE_RANGE2">6-8</option>
                        <option value="AGE_RANGE3">9 Up</option>
                        <option value="AGE_RANGE_ALL">All ages up to 12</option>
                      </select> 
                    </div>
                    <input type="text" class="form-control" v-model="applicationName" placeholder="Search play store applications">
                    <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" @click="searchPlayStore()" :disabled="isDisabled">
                        <i class="fa fa-search"></i>
                    </button>
                    </div>
                </div>
                <div class="app-listing-copy">
                    <ul id="product-directory">
                        <li v-for="application in playStoreApplications" :key="application.appId">
                            <img :src="application.icon" /><a href="javascript:void(0);" @click="createProfileApplication(application)" class="badge-icon"><i aria-hidden="true" class="fa fa-plus"></i></a>
                            <div class="app-listing-text">
                                <span class="apptext">
                                    <small><b>{{ application.title }}</b></small>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>    
        <div class="row">
            <div class="col-md-12 col-xl-12"> 
                <ul class="list-group app-list">
                    <li class="list-group-item d-flex justify-content-between align-items-center" v-for="application in applicationList" :key="application._id">
                        <span>
                          <img :src="application.app_icon" style="height: 50px; width: 50px;"><b>&nbsp;{{ application.display_text }}</b> - <small v-if="application.installationFailureReason" class="text-danger">{{ application.installationFailureReason }}</small>
                        </span>
                        <span class="float-right">
                            <a href="javascript:void(0)" @click="deleteProfileApplication(application.package_name)"><i class="fa fa-trash text-danger" aria-hidden="true"></i></a>
                        </span>
                    </li>
                </ul>
            </div>
            <!--<div class="col-md-12 text-center fixed-bottom mb-3"> 
                <button type="button" class="btn btn-primary w-100">Continue</button>
            </div>-->
        </div>

    </div>    
</template>

<script>
import ProfileService from "../services/ProfileService";
export default {
  name: 'Applications',
  data() {
    return {
        showPlayStoreSection: false,
        applicationName: "",
        playStoreApplications: [],
        profileId: this.$route.query.profileId,
        applicationList: [],
        isDisabled: false,
        age:"",
    }
  },
  methods: {
    getDeviceProfileApplicationList() {
      let data = {
        profile_id: this.profileId,
        install_type: "FORCE_INSTALLED",
      }
      ProfileService.getDeviceProfileApplicationList(data)
        .then((response) => {
          this.applicationList = response.data.data;
          let ageGroupArray = ["AGE_RANGE_ALL", "AGE_RANGE1", "AGE_RANGE2", "AGE_RANGE3"]
          if (ageGroupArray.includes(response.data.ageGroup)) {
            this.age = response.data.ageGroup;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    searchPlayStore() {
        if (!this.applicationName) {
            this.$toast.error("Enter application name for search");
            return;
        }
        this.isDisabled = true;
        let data = {
            app_name: this.applicationName,
            country:"us",
            age:this.age,
        }
        ProfileService.searchPlayStore(data)
        .then((response) => {
            this.playStoreApplications = response.data.data;
            this.isDisabled = false;
        })
        .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
        }); 
    },
    async createProfileApplication(app) {
    
        let masterApplicationData = {
            product_id: app.appId,
            package_name: app.appId,
            app_icon: app.icon,
            application_details: app,
            type: "Android"
        };
        await ProfileService.createMasterApplication(masterApplicationData)
        .then((response) => {
        }).catch((e) => {
        })

      let data = {
        profile_id: this.profileId,
        package_name: app.appId,
        is_web_app: false,
        type: "Android"
      };

      await ProfileService.createProfileApplication(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.getDeviceProfileApplicationList();
          this.playStoreApplications = [];
          this.applicationName = "";
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    deleteProfileApplication(package_name) {
      var data = {
        package_name: package_name,
        profile_id: this.profileId,
      };

      ProfileService.deleteProfileApplication(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.getDeviceProfileApplicationList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.getDeviceProfileApplicationList();
  },
}
</script>
