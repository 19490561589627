<template>
<div class="container">
    <div class="mb-3">
        <div style="float: left">
            <a href="javascript:void(0)" @click="redirectBack()"><i class="fa fa-fw fa-arrow-left"></i></a>
        </div>
        <div style="margin: 0 auto; width: 100px;"><h5><b>Secure Browser</b></h5></div>
    </div><hr>
    <div class="row">
        <div class="col-md-12 col-xl-12"> 
            <div class="form-group">
                <input type="url" v-model="url" autofocus class="form-control" placeholder="Enter URL">
            </div>
        </div>
        <div class="col-md-12 text-center mb-3"> 
            <button type="button" class="btn btn-primary w-100" @click="addBrowserUrl()" :disabled="isDisabled">Add</button>
        </div>
    </div>
  <div class="row">
    <div class="col-md-12 col-xl-12"> 
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">url</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody v-if="profileDetail">
                <tr v-for="url in allowBrowserUrls" :key="url">
                    <td>{{ url }}</td>
                    <td><a href="javascript:void(0)" @click="deleteUrl(url)"><i class="fa fa-fw fa-trash text-danger"></i></a></td>
                </tr>
            </tbody>
        </table>
	</div> 
  </div>

</div>
</template>

<script>
import ProfileService from "../services/ProfileService";
export default {
  name: 'Settings',
    data() {
        return {
            isDisabled: false,
            profileId: this.$route.query.profileId,
            url: "",
            profileDetail: null,
            allowBrowserUrls: [],
        }
    },
    methods: {
        async addBrowserUrl() {
            this.isDisabled = true;
            let data = {
                profile_id: this.profileId,
                url: this.url,
            }
            await ProfileService.addBrowserUrl(data)
            .then((response) => {
                this.isDisabled = false;
                this.url = "";
                this.$toast.success(response.data.respones_msg);
                this.getDeviceProfileDetail();
            }).catch((e) => {
                this.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
            });    
        },
        getDeviceProfileDetail() {
            ProfileService.getDeviceProfileDetail({profile_id: this.profileId})
                .then((response) => {
                    this.profileDetail = response.data.data;
                    this.allowBrowserUrls = response.data.data.allowBrowserUrls.reverse();
                }).catch((e) => {
                    this.$toast.error(e.response.data.respones_msg);
                });
        },
        async deleteUrl(url) {
            let result = confirm("Are you sure you wont to delete this url?");
            if (result === true) {
                let data = {
                    url: url,
                    profile_id: this.profileId,
                }
                await ProfileService.deleteUrl(data)
                .then((response) => {
                    this.$toast.success(response.data.respones_msg);
                    this.getDeviceProfileDetail();
                }).catch((e) => {
                    this.$toast.error(e.response.data.respones_msg);
                });   
            }    
        },
        async redirectBack() {
            return this.$router.push({ path: '/home', query: { profileId: this.profileId }});
        }
    },
    beforeMount() {
        this.getDeviceProfileDetail();
    },
}
</script>
